/* You can add global styles to this file, and also import other style files */

@import "~bootstrap/dist/css/bootstrap.css";
@import "../node_modules/ng2-slim-loading-bar/style.css";
@import url('https://fonts.googleapis.com/css?family=Questrial');
@import url('./assets/font-awesome/css/font-awesome.min.css');
@import "../node_modules/flag-icon-css/css/flag-icon.css";
/*@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";*/
@import "nbs.css";


:root{
    --primary: #030005;
    --white: #fff;
    --light-grey: #E3E3E3;
    --orange: #FEA500;
    --danger: #DC143C;
  --success:#00b871;
}

@font-face {
  font-family: Aller;
  src: url(assets/fonts/Aller/Aller_Rg.ttf) format("opentype");
}
.landingcard{
  background: #FFFFFF;
box-shadow: 0px 3.3612px 6.72241px rgba(0, 0, 0, 0.3);
border-radius: 26.8896px;
}

span:hover + div {
    display: block;
  }

  * {
    margin: 0;
    padding: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  html, body {
    font-family: 'Questrial', sans-serif;
  }

  /* for use with icons */
  i {
    margin: 0 10px 0 0;
  }

  /* aligns send/submit icon properly */
  i.fa.fa-send {
    margin: 5px 10px 0 0;
  }

  h1 {
    font-size: 25px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  h5 a {
    color: var(--orange);
  }

  h5 a:hover {
    text-decoration: none;
  }

  .button {
    background: var(--orange);
    display: inline-flex;
    color: var(--white);
    padding: 8px 38px;
    margin: 0 10px 10px 10px;
    /*float: left;*/
    border-radius: 25px;
    text-decoration: none;
    border: none;
    cursor: pointer;
    transition: all 0.5s;

  }

  .button-center {
    padding: 8px 38px;
    margin: 0 10px 10px 10px;
    border-radius: 25px;
    background: var(--primary);
    border: none;
    font-weight: bold;
    font-size: 1.5em;
    text-align: center;
    vertical-align: middle;
    color: white;
  }

  .button-grey-center {
    padding: 8px 38px;
    margin: 0 10px 10px 10px;
    border-radius: 25px;
    background: #9b9b9b;
    border: none;
    font-weight: bold;
    font-size: 1.5em;
    text-align: center;
    vertical-align: middle;
    color: white;
  }

  .button:hover {
    background-color: #222;
    color: white;
    border: none;
  }

  .button-back {
    background: var(--primary);
    color: var(--white);
    padding: 8px 38px;
    margin: 10px 0;
    border-radius: 25px;
    display: inline-block;
    text-decoration: none;
    border: none;
    cursor: pointer;
    transition: all 0.5s;
  }

  .button-back:hover {
    background: var(--orange);
    color: var(--white);
  }

  .hide-mobile {
    display: none;
  }

  .hide-desktop {
    display: inline-block;
  }

  @media (min-width: 768px) {
    .hide-mobile {
      display: inline-block;
    }

    .hide-desktop {
      display: none;
    }
  }


  /********************************
      HEADER
  ********************************/
  .header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background-color: var(--primary);
    color: var(--white);
    padding: 10px 0;
    z-index: 2;
  }

  .header-logo {
    float: left;
    padding-top: 20px;
  }

  /* Header Account */
  .header-account {
    /*z-index: 2000;*/
    padding: 20px 0 0 10px;
    float: right;
  }

  @media (max-width: 1000px) {
    .header-account {
      padding: 25px 0 20px 0;
      float: left;
    }
  }

  /* Header Buttons */
  .btn-signup a,
  .btn-login a {
    background: var(--orange);
    color: var(--white);
    padding: 10px 20px;
    margin: 0 10px 0 0;
    border-radius: 30px;
    text-align: center;
  }

  .btn-signup a:hover,
  .btn-login a:hover {
    opacity: 0.8;
    text-decoration: none;
  }

  .btn-avatar img {
    max-width: 45px;
    height: 44px;
  }

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
  /* Header Search */
  .header-search {
    padding: 8px 0 15px 0;
    float: left;
  }

  .header-search input[type="search"] {
    color: #777;
    font-size: 15px;
    border: 0px;
    padding: 10px;
  }

  .header-search-form {
    background: var(--white);
    color: #777;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 100%;
    /* padding: 10px; */
  }

  .header-search-form button {
    background: transparent;
    border: none;
    color: var(--orange);
    font-size: 25px;
    padding: 4px 0 0 5px;
    float: right;
    transition: all 0.6s;
  }

  .header-search-form button:hover {
    cursor: pointer;
  }


  .header-search-form input[type=text] {
    outline: none;
    padding: 3px 0px 3px 3px;
    margin: 5px 1px 3px 0px;
    border: 1px solid #DDDDDD;
  }

  .header-search-form input[type=text]:focus {
    box-shadow: 0 0 5px var(--orange);
    padding: 3px 0px 3px 3px;
    margin: 5px 1px 3px 0px;
    border: 1px solid var(--orange);
  }

  .header-search-form select {
    border: none;
    background: transparent;
    color: #777;
  }

  select {
    /* styling */
    display: inline-block;
    padding: 0.5em 2.5em 0.5em 1em;
    font: inherit;
    /* reset */
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  select.select-search {
    background-image: linear-gradient(45deg, transparent 50%, var(--orange) 50%), linear-gradient(135deg, var(--orange) 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
  }

  select.select-search:focus {
    background-image: linear-gradient(45deg, var(--orange) 50%, transparent 50%), linear-gradient(135deg, transparent 50%, var(--orange) 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border-color: var(--orange);
    outline: 0;
  }

  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 var(--primary);
  }

  /********************************
	DROP DOWNS
********************************/
.droplink {
  background-color: transparent;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.droplink::after {
  content: "\f0d7";
  font-family: FontAwesome;
  color: #FEA500;
  padding: 10px;
}

.mydropdown {
  display: block;
  padding: 22px 15px 0 15px;
  float: left;
  z-index: 2000;
}

.mydropdown a {
  color: #4a4a4a;
  text-decoration: none;
  padding: 0;
  font-size: 16px;
}

.mydropdown-signup {
  position: relative;
  display: inline-block;
  margin-top: -16px;
  z-index: 1200;
}

.dropdown-content {
  display: none;
  position: absolute;
  border-radius: 5px;
  background-color: #fbfbfb;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1400;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #FEA500;
  color: white;
}

.mydropdown-signup .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1200;
}

.mydropdown:hover .dropdown-content,
.mydropdown-signup:hover .dropdown-content {
  display: block;
}

.dropdown:hover .droplink {
  background-color: #3e8e41;
}

@media (max-width: 1000px) {
  .header-logo {
    width: 100%;
  }

  .mydropdown {
    width: 100%;
    padding: 20px 0;
  }

  .header-search {
    width: 100%;
  }
}


/*************************************
	SLIDER
*************************************/
.slider {
  background: black;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0;
}

.carousel-inner {
  position: relative;
  width: 100%;
  max-height: 400px;
  height: auto;
}

.carousel-item {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: -webkit-transform 0.6s;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
}

.carousel-item.active {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -webkit-animation: scaleAnimation 8s;
  animation: scaleAnimation 8s;
}

@-webkit-keyframes scaleAnimation {
  from {
    -webkit-transform: none;
    transform: none;
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@keyframes scaleAnimation {
  from {
    -webkit-transform: none;
    transform: none;
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

.carousel-control-prev,
.carousel-control-next {
  display: none;
}

.slider-caption {
  position: absolute;
  left: 0;
  right: 0;
  padding: 20px 0 !important;
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
  text-align: center;
  top: 100px;
}

@media (max-width: 1000px) {
  .slider-caption {
    top: 400px;
  }
}

@media (max-width: 768px) {
  /* Used When .hide-desktop class kicks in - what line is it on */
  .slider-caption {
    position: relative;
    padding: 0;
    top: 0;
  }
}

.slider-caption > h3 {
  font-size: 50px;
  font-weight: 300;
  color: #fff;
  text-shadow: 2px 2px 2px #333;
}

.slider-search-form {
  opacity: 0.8;
  background: #fff;
  color: #777;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 25px;
  position: relative;
  width: 100%;
  max-width: 600px;
  padding: 10px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .slider-search-form {
    max-width: 90%;
  }
}

.slider-search-form select {
  border: none;
  color: #777;
  font-size: 15px;
}

@media (max-width: 400px) {
  .slider-search-form select {
    display: none;
  }
}

.slider-search-form button {
  background: transparent;
  border: none;
  font-size: 22px;
  float: right;
}

.slider-search-form button::before {
  content: "\f002";
  color: #FEA500;
  font-family: FontAwesome;
}

@media (max-width: 600px) {
  .slider-search-form button {
    display: none;
  }
}

.slider-caption input[type="search"] {
  color: #777;
  font-size: 16px;
  border: 0px solid #ddd;
  width: 100%;
  padding: 0 10px;
  margin: 6px 0 0 0;
}

.slider-caption input[type="image"] {
  position: absolute;
  right: 113px;
  max-width: 26px;
  max-height: 26px;
  transform: translate(-4px, 18px);
}

.slider-caption input[type="image"]:hover {
  opacity: 0.8;
}


/******************************
	HOME PAGE - WELCOME
******************************/
.welcome-content {
  text-align: center;
  padding: 10px 10px 10px 10px;
}

.welcome-content h4 {
  margin: 10px 0;
}

.welcome-content p {
  text-align: left;
  margin: 10px;
}

.welcome-content img {
  max-height: 400px;
  height: 100%;
}

/* Home Page Tabs */
.home-tabs .nav-pills {
  margin: 0 auto;
  padding: 30px 0;
}

.home-tabs .nav-pills ul { /* centre tab buttons on page */
  width: auto;
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.home-tabs .nav-pills ul li { /* place tab buttons next to each other */
  float: left;
  list-style: none;
  margin-right: 1rem;
}

.home-tabs .nav-pills li a {
  background: #f4f4f4;
  color: #9b9b9b;
  padding: 10px 25px;
  border-radius: 25px;
  text-decoration: none;
  display: inline-block;
}

.home-tabs .nav-pills li a:hover,
.home-tabs .nav-pills li a.active {
  background: #f4f4f4;
  color: #FEA500;
}


/**********************************************
		Home Gallery
**********************************************/
/* .gallery-container {
	margin: .5vw;
	display: -ms-flex;
	flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-ms-flexbox-direction: column;
	-webkit-flex-flow: row wrap;
	flex-flow: row wrap;
	display: -webkit-box;
	display: flex;
}

.gallery-container div {
	-webkit-box-flow: auto;
	-ms-flex: auto;
	flex: auto;
	width: 300px;
	margin: .5vw;
}

.gallery-container div img {
	width: 100%;
	height: auto;
}

.gallery-img-content {
	position: relative;
	width: 300px;
	margin: auto;
	overflow: hidden;
}

.gallery-img-content .gallery-overlay {
	background: rgba(0,0,0,0.7);
	position: absolute;
	height: 100vh;
	width: 100vw;
	left: -10px;
	top: -10px;
	opacity: 0;
	-webkit-transition: all 0.4s ease-in-out 0s;
	transition: all 0.4s ease-in-out 0s;
}

.gallery-img-content:hover .gallery-overlay{
	opacity: 1;
	cursor: pointer;
}

.gallery-details {
	position: absolute;
	text-align: center;
	padding-left: 1em;
	padding-right: 1em;
	width: 100%;
	top: 50%;
	left: 50%;
	opacity: 0;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

.gallery-img-content:hover .gallery-details{
	top: 50%;
	left: 50%;
	opacity: 1;
}

.gallery-details p {
	color: #fff;
	font-size: 20px;
}
.gallery-details p::before {
	content: "\f06e";
	font-family: FontAwesome;
	padding: 0 15px 0 0;
}

.gallery-detail-animate-in {
	top: 80%;
} */


/**********************************************
		MASONRY GALLARY LAYOUT
**********************************************/
/*	Gallery fixed height and auto width fixed */
.masonry-container {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 20px;
}

.masonry {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%;
  /* float: left; */
}

.masonry-float {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  /* width: 100%; */
  float: left;
}

.masonry-brick {
  height: 195px;
  width: auto;
  overflow: hidden;
  border-radius: 5px;
  left: -8px;
  margin: 0 0 8px 8px;
  background-color: #d8d8d8;
  position: relative;
}

.masonry-brick .masonry-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 100vh;
  width: 100vw;
  left: -10px;
  top: -10px;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.masonry-brick:hover .masonry-overlay {
  opacity: 1;
  cursor: pointer;
}

.masonry-brick-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.masonry-brick:hover .masonry-brick-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.masonry-brick-details p {
  color: #fff;
  font-size: 16px;
}

.masonry-brick-details p::before {
  content: "\f06e";
  font-family: FontAwesome;
  padding: 0 15px 0 0;
}

.masonry-brick-details-animate-in {
  top: 80%;
}


/*********************************************
	FOOTER STYLES
**********************************************/
.footer {
  background: var(--primary);
  padding: 15px 0;
  margin: 50px 0 0 0;
}

.footer h4 {
  color: #FFFFFF;
  font-size: 20px;
}

.footer ul {
  padding: 0;
  list-style: none;
}

.footer a,
.footer p,
.footer a:not([href]):not([tabindex]) {
  text-decoration: none;
  color: #FFFFFF;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.5s;
}

.footer a:hover,
.footer a:not([href]):not([tabindex]):hover {
  padding: 0 0 0 10px;
  color: #FEA500;
  text-decoration: underline;
}


.footer img {
  width: 31px;
  margin: 12px;
}


/*********************************************
	SEARCH ITEM - LAST NODE
**********************************************/
.ci-last-node-image {
  position: relative;
  margin-bottom: 15px;
}

.ci-last-node-image a img {
  width: 100%;
}

.ci-last-node-image-caption {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  bottom: 0;
  width: 100%;
  padding: 0 15px 0 15px;
  font-size: 17px;
}

.ci-last-node-image-caption div {
  width: 50%;
  float: left;
}

.ci-last-node-image-caption div p {
  text-transform: capitalize;
  padding: 10px 0 0 0;
}

.ci-last-node-image-caption .add-to-light-box {
  cursor: pointer;
  transition: all 0.5s;
}

.ci-last-node-image-caption .add-to-light-box:hover {
  color: #FEA500;
  padding: 0 0 0 10px;
}

.ci-last-node-image-caption div p img {
  padding: 0 10px 0 0;
}

.ci-last-node-details {
  background: #f5f7fa;
  padding: 10px 0 0 10px;
}

.ci-last-node-details h3 {
  font-size: 22px;
  color: #444;
  padding: 0 0 0 5px;
}

.img-dim,
.img-type {
  font-size: 15px;
  display: inline-table;
  margin: 0 5px;
}

.img-dim {
  display: inline-block;
  min-width: 85px;
}

.img-type {
  display: inline-block;
  min-width: 30px;
}


/* Related Images */
.btn-download span::after {
  content: "\f019";
  font-family: FontAwesome;
  padding: 0 0 0 10px;
}

.btn-download a {
  background: #69BA10;
  color: #fff;
  cursor: pointer;
  padding: 10px 20px;
  margin: 0 0 20px 0;
  border-radius: 30px;
  display: inline-block;
  text-decoration: none;
  transition: all 0.5s;
}

.btn-download a:hover {
  background: rgb(88, 156, 14);
  color: #222;
}

.related-images-content {
  margin: 0 0 50px 0;
}

.related-images-content h3 {
  font-size: 18px;
}

.related-images-content .img-size {
  display: inline-table;
  font-size: 14px;
  background: #f4f4f4;
  color: #9b9b9b;
  padding: 8px;
  margin: 0 0 15px 0;
}

img.related-images {
  cursor: pointer;
  padding: 10px 0;
  opacity: 1;
  float: left;
  width: 20%;
  margin: 0 10px 0 0;
}

img.related-images:hover {
  opacity: 0.5;
}

@media (max-width: 769px) {
  img.related-images {
    width: 40%;
  }
}

@media (max-width: 600px) {
  img.related-images {
    width: 100%;
  }
}


/*********************************************
	LOGIN FORM & EMAIL RECOVERY
**********************************************/
.login-form {
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
  padding: 40px 0;
}

.login-form a,
.login-form a:not([href]):not([tabindex]) {
  text-decoration: none;
  color: #666666;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.5s;
  padding: 0;
}

.login-form a:hover,
.login-form a:not([href]):not([tabindex]):hover {
  padding: 0 0 0 10px;
  color: #FEA500;
  text-decoration: underline;
}


/**********************************************
	BECOME A CONTRIBUTOR MODAL
**********************************************/

.modal-title h5 {
  cursor: pointer;
  color: #666666;
}


/*********************************************
	BUTTONS
**********************************************/
.btn-next-page {
  display: block;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
}

.btn-next-page a {
  background: #FEA500;
  color: #fff;
  padding: 5px 25px;
  border-radius: 25px;
  display: inline-block;
  text-decoration: none;
}

.btn-more {
  display: block;
  margin: 50px auto;
  text-align: center;
}

.btn-more a {
  background: #FEA500;
  color: #fff;
  padding: 10px 20px;
  border-radius: 25px;
  display: inline-block;
  text-decoration: none;
}

.btn-more a:hover,
.btn-next-page a:hover {
  opacity: 0.8;
}


.image-col {
  padding: 0;
}

.img-btn {
  font-size: 14px;
}

.caption {
  border: 1px solid #f1f1f1;
  background: #f9f9f9;
  font-size: 14px;
  padding: 5px;
}

.search-stats-bar {
  background: #fafafa;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 45px;
  padding-left: 45px;
}

.search-stats-bar button {
  border: 1px solid #ddd;
  border-radius: 3px;
  color: #666;
  font-size: 14px;
  padding-bottom: 2px;
}

.search-stats-bar .pager-text {
  color: #666;
  font-size: 13px;
}

.search-results-options a {
  color: #999;
  font-size: 13px;
  display: inline-block;
  margin-left: 10px;
  text-decoration: none;
}

.search-results-options a:hover {
  opacity: 0.7;
}

.search-results-options {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 45px;
  padding-left: 45px;
}


/*********************************************
	SIDEBAR NAV
**********************************************/
#sidebar {
  overflow: hidden;
  z-index: 3;
}

#sidebar .list-group {
  background-color: #f9f9f9;
  /* min-height: 100vh; */
}

#sidebar .list-group-item {
  padding-left: 20px;
  border-color: #ddd;
  white-space: nowrap;
}

/* highlight active menu */
#sidebar .list-group-item:not(.collapsed) {
  background-color: #eee;
}

/* closed state */
#sidebar .list-group .list-group-item[aria-expanded="false"]::after {
  content: " \f0d7";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 5px;
}

/* open state */
#sidebar .list-group .list-group-item[aria-expanded="true"] {
  background-color: #ddd;
}

#sidebar .list-group .list-group-item[aria-expanded="true"]::after {
  content: " \f0da";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 5px;
}

/* level 1*/
#sidebar .list-group .collapse .list-group-item,
#sidebar .list-group .collapsing .list-group-item {
  padding-left: 20px;
}

/* level 2*/
#sidebar .list-group .collapse > .collapse .list-group-item,
#sidebar .list-group .collapse > .collapsing .list-group-item {
  padding-left: 30px;
}

/* level 3*/
#sidebar .list-group .collapse > .collapse > .collapse .list-group-item {
  padding-left: 40px;
}

#sidebar .list-group-item:hover {
  background: #ddd;
}

@media (max-width: 768px) {
  #sidebar {
    min-width: 35px;
    max-width: 40px;
    overflow-y: auto;
    overflow-x: visible;
    transition: all 0.25s ease;
  }

  #sidebar.show {
    transform: translateX(0);
  }

  #sidebar::-webkit-scrollbar {
    width: 0px;
  }

  #sidebar,
  #sidebar .list-group {
    min-width: 35px;
    overflow: visible;
  }

  /* overlay sub levels on small screens */
  #sidebar .list-group .collapse.show,
  #sidebar .list-group .collapsing {
    position: relative;
    z-index: 1;
    width: 190px;
    top: 0;
  }

  #sidebar .list-group > .list-group-item {
    text-align: center;
    padding: .75rem .5rem;
  }

  /* hide caret icons of top level when collapsed */
  #sidebar .list-group > .list-group-item[aria-expanded="true"]::after,
  #sidebar .list-group > .list-group-item[aria-expanded="false"]::after {
    display: none;
  }
}

.collapse.show {
  visibility: visible;
}

.collapsing {
  visibility: visible;
  height: 0;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.collapsing.width {
  -webkit-transition-property: width, visibility;
  transition-property: width, visibility;
  width: 0;
  height: 100%;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}


/*****************************************
	BUTTONS, FORMS and ACCORDIONS
*****************************************/
main input,
main textarea,
main checkbox,
main radio,
main select {
  border: 1px solid #ccc !important;
  border-radius: 3px !important;
  background: #f9f9f9 !important;
}

/* Buttons Override */
.btn-success {
  background: #69BA10 !important;
  border-color: #69BA10 !important;
}

.btn-success:hover {
  opacity: 0.8;
}

button.btn.btn-primary,
button.btn.btn-success.mt-2,
.btn-success {
  background: #FEA500;
  color: #fff;
  padding: 8px 38px;
  margin: 10px 0;
  border-radius: 25px;
  display: inline-block;
  text-decoration: none;
  border: none;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #FEA500;
  border: none;
}


fieldset {
  background: #F4F7FA;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
}

legend {
  background: #fefefe;
  display: inline !important;
  padding: 5px;
  font-size: 16px;
  /* width: auto; */
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #333;
}

label {
  color: #444;
  font-size: 1em;
}

th a {
  color: var(--primary);
}

th {
  background: var(--white);
  color: var(--primary);
  border: none;
  opacity: 0.8;
}


/**********************************************
		Cards - Override styles
**********************************************/
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: none;
  border-radius: 10px;
  /*box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.11);*/
}

.card-body {
  flex: 1 1 auto;
  padding: 5px 25px;
}

.card-header {
  background: white;
  color: #555555;
  font-size: 16px;
  transition: all 0.6s;
}

.card-header:hover,
.card-header.active {
  background: #F3F3F3;
  color: white;
  cursor: pointer;
}

.card-header a {
  color: #555555;
  text-decoration: none;
}


.list-group-item,
.list-group-item a {
  position: relative;
  display: block;
  padding: 10px 0 10px 20px;
  background-color: #fff;
  color: #555555;
  border: none;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.125); */
  transition: all 0.6s
}

.list-group-item:hover,
.list-group-item a:hover {
  padding: 10px 0 10px 25px;
  color: #FEA500;
}

.list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: none;
}


/* div#static-1-header a,
div#static-2-header a,
div#static-3-header a,
div#static-4-header a,
div#static-5-header a {
	color: #666;
	text-decoration: none;
} */

/* div#static-1,
div#static-2,
div#static-3,
div#static-4,
div#static-5 {
	max-height: 200px;
	overflow-y: auto;
} */

/* div#static-1 a,
div#static-2 a,
div#static-3 a,
div#static-4 a,
div#static-5 a {
	color: #666;
	background-color: #eee;
	text-decoration: none;
} */

/* .card-header:hover,
div#static-1-header a:hover,
div#static-2-header a:hover,
div#static-3-header a:hover,
div#static-4-header a:hover,
div#static-5-header a:hover,
div#static-1 a:hover,
div#static-2 a:hover,
div#static-3 a:hover,
div#static-4 a:hover,
div#static-5 a:hover, {
	background-color: #ddd;
	text-decoration: none;
} */


[_nghost-c6] table tr th a {
  color: #fff !important;
}

.list-group a:hover {
  background-color: #ddd;
  text-decoration: none;
}

.list-group a {
  background-color: #eee;
  color: #666;
  text-decoration: none;
}


h4.p-3.mb-2.bg-light {
  width: 100%;
}

.bg-info {
  background-color: #f8f9fa !important;
}

.bg-info label {
  color: #000;
  font-size: 16px;
  padding: 10px;
  font-weight: bold;
}

.panel.panel-primary {
  width: 100%;
}

.mcont {
  float: left;
  margin: 10px 10px;
}

div#static-2 a.active {
  background: #f1f1f1;
}

td .bg-success {
  background-color: #f4f4f4 !important;
  color: #FEA500;
  padding: 10px 25px;
  border-radius: 25px;
  text-decoration: none;
}

td button.bg-success:hover {
  background-color: #f4f4f4 !important;
  color: #FEA500;
}

h4.bmen {
  color: #FEA500;
  font-size: 17px;
}

button.mcrt {
  background: #FEA500;
  color: #fff;
  padding: 10px 20px;
  border-radius: 25px;
  display: inline-block;
  text-decoration: none;
  border: none;
}

button.mcrt:hover {
  cursor: pointer;
}

.thirty {
  width: 33%;
  margin: 0px !important;
  float: left;
}

button.btn.btn-default {
  color: #fff;
  background: #9B9B9B;
  padding: 6px 38px;
  border-radius: 25px;
  text-transform: capitalize;
}

button.btn.btn-warning.clo[_ngcontent-c5] {
  color: #fff;
  background: #9B9B9B;
  padding: 6px 38px;
  border-radius: 25px;
  text-transform: capitalize;
  border: none;
  float: right;
}


/*********************************************
	Responsive Framework
**********************************************/
.content {
  position: relative;
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  clear: both;
}

.content:after {
  content: "";
  display: table;
  clear: both;
}

.column {
  width: 100%;
  float: left;
  /*margin-top: 30px;*/
  box-sizing: border-box;
}

@media (min-width: 769px) {
  .column {
    margin-left: 3%;
  }

  .column:first-child,
  .column:nth-child(5n+0) {
    margin-left: 0;
  }

  .half {
    width: 48.5%;
  }

  .third {
    width: 31.3333333333%;
  }

  .two-thirds {
    width: 65.6666666667%;
  }

  .quarter {
    width: 22.75%;
  }

  .three-quarter {
    width: 77.25%;
  }

  .full {
    width: 100%;
  }
}


/*********************************************
	UNUSED CODE - Please leave in here until we are certain and ready for deletion
**********************************************/
.main-container {
  position: relative;
  padding: 0;
}

.main {
  min-height: 102.5vh;
}

.cartModal {
  position: fixed;
  top: 75px;
  right: 0;
  bottom: 0;
  left: auto;
  min-width: 250px;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.primary-color {
  color: #1E105F;
}

.ascent-color {
  color: #FEA500;
}

/*::ng-deep*/
.mat-dialog-container {
  background: linear-gradient(rgb(230, 230,230), rgb(255, 255, 255));
  border-radius: 10%;
  padding: 5px;
}


.bg-grad {
  background-image: linear-gradient(rgb(211,211,211), rgb(211,211,211));
  padding: 20px;
}

.bg-grad-dashboard {
  background-image: linear-gradient(rgb(220,220,220), rgb(211,211,211));
  height: 97vh; /* For 100% screen height */
}
.bg-white-grad-dashboard {
  height: 97vh; /* For 100% screen height */
}
.element {

}

.image_box {
  height: 55px;
  border-color: #818082, 100%;
  background-color: transparent;
  color: #818082;
  width: 380px;
  padding: 10px;
}


.p-margin {
  margin: 10px;
}

.c-align {
  text-align: center;
}

.white {
  color: white;
}

.mat-divider {
  border-top-color: grey;
}

.mat-dialog-container {
  color: white;
}

.pilim-form {
  min-width: 150px;
  max-width: 500px;
  width: 100%;
}

.full-width {
  width: 80%;
}

.mat-form-​field-appearance-outline .mat-form-field-outline {
  color: white;
}

.mat-form-​field-appearance-outline .mat-form-field-outline {
  color: white;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}


.mat-form-field.mat-form-field {
  color: white;
}

textarea:focus, input:focus {
  outline: none;
}


::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: white;
}

input {
  caret-color: #FEA500;
}

.iti__country {
  color: #1E105F;
}


.space-between {
  display: flex;
  justify-content: space-between;
}

.space {
  display: flex;
  justify-content: left;


}

.space-evenly {
  display: flex;
  justify-content: space-evenly;

}

.dashboard-value {
  color: #9B9B9B;
  font-size: 16px;
  padding-left: 20px;

}

.circle-img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.circle-wrapper {
  height: 100px;
  circle: 100px;
  overflow: hidden;
  border-radius: 50%;
}


.btn-nav-bar {

  padding: 16px 38px;
  margin: 0 10px 10px 10px;
  border-radius: 5px;
  background: #1E105F;
  border: none;
  text-align: center;
  vertical-align: middle;
  color: white;
  width: 70%;
  font-size: 14px;

}

.btn-nav-bar-white {
  padding: 16px 38px;
  margin: 0 10px 10px 10px;
  border-radius: 5px;
  background: white;
  border: none;
  text-align: center;
  vertical-align: middle;
  color: #1E105F;
  width: 70%;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.mat-list-base .mat-list-item{
}


.mat-list-base .mat-list-item, .mat-list-base .mat-list-option {
  width: 0%;
}
.iti__arrow{
  border-top: 4px solid #FFFFFF;
}

.mat-dialog-container {
  border-radius: 30px !important;
}

.ngx-drag-container{
  border: 2px dotted #1E105F;
  border-radius: 30px;
  height: auto;
  background-color: white;
  margin: auto;
  text-align: center;
  padding: 20px;
}

.ngx-dragging-container {
  border: 2px dotted #1E105F;
  border-radius: 30px;
  height: auto;
  background-color: grey;
  margin: auto;
  text-align: center;
  padding: 20px;
}

.ngx-container{
  border: 2px solid #1E105F;
  border-radius: 30px;
  height: auto;
  background-color: white;
  margin: auto;
  text-align: center;
  padding: 20px;
}


.input-rounded-decoration{
  border: 1px solid grey;
  border-radius: 10px;
  width: 70%;
  /* padding: 10px; */
}

.mat-form-field-wrapper {
  padding-bottom: 0em;
}

.mat-form-field-underline {
  display: none;
}

.mat-select-panel {
  background: white;

}

mat-form-field {
  margin-right: 12px;
}

.mat-select-value-text {
  color: #1E105F !important;
}


.mat-form-field .mat-form-field-flex{
  background-color: white;
}





.mat-form-field.mat-form-field {
  color: black;
}

.userPhoto{
  max-width: 60%;
  height: auto;
  margin-left: 10%;
  margin-right: 10%;
}

.agentCard{
  max-width: 100%;
  height: auto;
  margin-left: 2%;
  margin-right: 2%;
}
.icon-display {
  transform: scale(4);
}

.icon-display-fields{
  transform: scale(0.5)
}
.fields-icon-color {
  color: green;
}
.mat-bottom-sheet-container {
  min-height: 40vh;
  min-width: 75vw !important;
  margin-top: 80px;
  margin-left: 16%;
}

input.star:checked ~ label.star:before {
  color: green !important;
}

label.star:before {
  font-size: 3rem;
}

div.stars {
  width: auto !important;
}

::ng-deep .mat-progress-bar-fill::after {
  background-color: white;
}

::ng-deep .mat-progress-bar-buffer {
  background: white;
}

::ng-deep .mat-progress-bar {
  border-radius: 2px;
}

mat-progress-bar .mat-progress-bar-buffer {
  background: white;
}


/* CUSTOM */
.myInput{
  background: #F8F8F8;
border: 1px solid #818082;
box-sizing: border-box;
border-radius: 5px;
color: black;
padding: 10px;
width: 100%;
}

.myFormStyle .myInput::placeholder, .myInputPhoneNumberStyle::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000;
  opacity: 1 !important; /* Firefox */
}

.myFormStyle .myInput:-ms-input-placeholder,.myInputPhoneNumberStyle:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #000;
}

.myFormStyle .myInput::-ms-input-placeholder, .myInputPhoneNumberStyle::-ms-input-placeholder { /* Microsoft Edge */
  color: #000;
}




.myFormStyle .form-group{
  margin-bottom: 20px;
}

.labelSuccess{
  background-color: var(--success);
}
.labelDanger{
  background-color: var(--danger);
}

.labelBlack{
  background-color: black;
  color: #fff;
}

.statusLabel{
  color: white;
  text-decoration: none;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  /* min-width: 64px; */
  /* line-height: 36px; */
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
  text-align: center;
  text-align: center;
}

.myFormStyle .form-group label{
  font-family: Aller;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;
color: #030005;
margin-bottom: 5px;
}

.myFormStyle footer{
  text-align: center;
}

.myFormStyle button{
  margin: 5px;
  border-radius: 5px !important;
}

.myFormStyle .no_image_box {
  height: 100% !important;
}

.myInputPhoneNumberStyle{
   background: #F8F8F8;
border: 1px solid #818082;
box-sizing: border-box;
border-radius: 5px;
color: black;
padding: 10px;

}

ngx-intl-tel-input .iti{ width: 100% !important; }

.help-block{
  color: red;
}

.hidden{
  display: none;
}


